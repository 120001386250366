import React from "react"
import { graphql } from "gatsby"
import MarkdownPage from "components/MarkdownPage"

export const query = graphql`
  query ArticlePage($slug: String!) {
    article(slug: { eq: $slug }) {
      body_html
      canonical_url
      edited_at
      title
    }
  }
`

interface ArticleTemplateProps {
  data: {
    article: Pick<
      GatsbyTypes.Article,
      "body_html" | "canonical_url" | "edited_at" | "title"
    >
  }
}
export default function ArticleTemplate({
  data: { article },
}: ArticleTemplateProps) {
  return (
    <MarkdownPage
      body={article.body_html}
      canonicalUrl={article.canonical_url}
      date={article.edited_at}
      title={article.title}
    />
  )
}
